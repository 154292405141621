import create from 'zustand'

const [useStoreObjs] = create(set => ({
    objs: [],
    add: ({ mesh, coords }) => set(state => ({ objs: [...state.objs, { mesh, coords }] })),
    remove: ({ mesh }) => set(state => ({ objs: state.objs.filter(obj => obj.mesh.uuid !== mesh.uuid) }))
}))
  
const [useStoreCesium] = create(set => ({
    cesium: null,
    add: (cesium) => set({ cesium }),
    remove: () => set({ cesium: null })
}))
const [useStorePotree] = create(set => ({
    potree: null,
    add: (potree) => set({ potree }),
    remove: () => set({ potree: null })
}))

const [useBoundingBox] = create(set => ({
    minWGS84: [115.23, 39.55],
    maxWGS84: [116.23, 41.55],
    setMin: (min) => set({ minWGS84: min }),
    setMax: (max) => set({ maxWGS84: max }),
}))

const [useCoords] = create(set => ({
    x: 0,
    y: 0,
    z: 0,
    setCoords: (x, y, z) => set({ x, y, z })
}))

const [useIonId] = create(set => ({
    id: 0,
    setId: (id) => set({ id })
}))

const [useLoading] = create(set => ({
    loading: true,
    setIsLoading: () => set({ loading: true }),
    setIsLoaded: () => set({ loading: false }),
}))

const [useRouteChangeLoading] = create(set => ({
    loading: false,
    setIsLoading: () => set({ loading: true }),
    setIsLoaded: () => set({ loading: false }),
}))

export {
    useBoundingBox,
    useStoreObjs,
    useStoreCesium,
    useCoords,
    useStorePotree,
    useIonId,
    useLoading,
    useRouteChangeLoading
}