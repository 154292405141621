import React from "react";
import styled, { css } from "styled-components";

import logoImg from "./img/logo.png";

import { useLoading } from "./store";
import SvgLogo from "./Logo";
import Pannellum from "./Pannellum";

function Viewers() {
  const [path, setPath] = React.useState();

  const loading = useLoading((state) => state.loading);
  const setIsLoaded = useLoading((state) => state.setIsLoaded);

  function getPanoIdFromUrl() {
    const pathName = window.location.pathname;
    const pathArray = pathName.split("/");

    if (pathArray[1] !== "pano") return;
    console.log(pathArray);
    if (pathArray[2]) {
      if (pathArray[2] === "id") {
        setPath(
          `https://pano-node-converter.s3.eu-central-1.amazonaws.com/${pathArray[3]}/${pathArray[4]}`
        );
      } else setPath(`${process.env.REACT_APP_S3_PATH}/${pathArray[2]}`);
    }
  }

  React.useEffect(() => {
    getPanoIdFromUrl();
    setIsLoaded();
  }, [setIsLoaded]);

  return loading ? (
    <Loader>
      <SvgLogo
        style={{
          transform: "scale(0.15)",
        }}
      />
    </Loader>
  ) : (
    <>
      <Header>
        <Logo src={logoImg} />
      </Header>
      {path && <Pannellum basePath={path} />}
    </>
  );
}

const Header = styled.div`
  position: fixed;
  z-index: 999;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 100%
  );

  display: flex;
  align-items: center;
  left: 0;
  right: 0;

  padding: 1rem;
  padding-bottom: 6rem;

  color: white;

  > * + * {
    margin-left: 2rem;
    padding-left: 2rem;

    border-left: 1px solid rgba(255, 255, 255, 0.5);
  }
`;

const Loader = styled.div`
  display: flex;
  justify-content: space-around;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(25, 24, 27, 1);
  align-items: center;
  z-index: 1000;
  ${(props) =>
    props.small &&
    css`
      z-index: 9;
    `}
`;

const Logo = styled.img`
  width: 120px;
`;

export default Viewers;
