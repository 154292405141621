import React from "react";

const SvgLogo = props => (
  <svg width={250} height={314} {...props}>
    <g fill="#FFF" fillRule="evenodd">
      <path d="M.4 254.1l65 65.4v-85c.1-18.2 6.8-32.9 32.8-32.9-45.9-25.5-89.9 10-97.8 52.5z" />
      <path d="M.4 254.1v-20.6c0-70.6 36-98.9 98-98.9h45.2c18-.1 32.6-14.9 32.6-33.1 0-18.2-14.6-32.9-32.6-33h-.2.2c70.8-7.2 105.1 13 100.3 67.3-14.9 42.3-54.8 65.8-98.8 65.8h-47c-44.7.1-83.5 12.3-97.7 52.5" />
      <path d="M244 135.8c0-55.4-30.8-67.3-100.3-67.3H65.4L.4.9h125.5c80.3 0 123.7 43.3 123.7 101.8 0 11.7-2 22.8-5.6 33.1z" />
    </g>
  </svg>
);

export default SvgLogo;