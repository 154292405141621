import React from "react"

const pannellum = window.pannellum

const ZOOM_FACTOR = 64

function Pannellum(props) {
    const {
        type,
        basePath,
        showControls = false,
        autoLoad = true,
        friction = 1.0,
        multiRes
    } = props

    const {
        cubeResolution,
        ...allTheRest
    } = multiRes

    const ref = React.useRef()
    const viewer = React.useRef()

    React.useEffect(() => {
        viewer.current = pannellum.viewer(ref.current, {
            type,
            showControls,
            basePath,
            autoLoad,
            friction,
            multiRes: {
                cubeResolution: cubeResolution * ZOOM_FACTOR,
                ...allTheRest,
            }
        });

        // viewer.current.on('mousedown', function(event) {
        //     // coords[0] is pitch, coords[1] is yaw
        //     var coords = viewer.current.mouseEventToCoords(event);
        //     // convert to image coordinates
        //     var x = (coords[1] / 360 + 0.5) * 512;
        //     var y = (0.5 - coords[0] / 180) * 512;
        // });

    }, [])

    return <div ref={ref} />
}

function Init(props) {
    const { basePath } = props
    const [settings, setSettings] = React.useState()

    React.useEffect(() => {
        async function fetchSettings() {
            const data = await fetch(`${basePath}/config.json`)
                .then((response) => {
                    return response.json();
                })
                .catch(function (error) {
                    console.log(error);
                });
                setSettings(data)
        }
        fetchSettings();
    }, [basePath]) 

    return (settings && settings.multiRes) ? (
        <Pannellum
            basePath={basePath}
            type={settings.type}
            multiRes={settings.multiRes}
        />
    ) : null
}

export default Init